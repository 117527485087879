import { useState } from "react";

export default function Social({ href, alt, rest, act }) {
  const [isHover, setIsHover] = useState(false);
  return (
    <a href={href} target="_blank" rel="noopener noreferrer">
      <img
        className="footer__secondary__socials__icon"
        onMouseEnter={() => setIsHover(true)}
        onMouseOut={() => setIsHover(false)}
        src={isHover === true ? act : rest}
        alt={alt}
      />
    </a>
  );
}
