import { Link } from "react-router-dom";

import "./index.css";

// Used in Hero Section and Footer Section
export default function LinkButton({
  children,
  to,
  isDark = false,
  isSmall = false,
  onClick = null,
}) {
  let genericClasses = "genericLinkButton";
  if (isDark) {
    genericClasses += " genericLinkButton--dark";
  }
  if (isSmall) {
    genericClasses += " genericLinkButton--small-font";
  }

  return (
    <Link
      to={to}
      className={genericClasses}
      onClick={onClick ? onClick : undefined}
    >
      {children}
    </Link>
  );
}
