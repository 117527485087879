import { Link, Outlet, useLocation } from "react-router-dom";

import "./index.css";

const links = [
  { to: "warranty", title: "Warranty Statement" },
  { to: "terms", title: "Terms & Conditions" },
  { to: "privacy", title: "Privacy Policy" },
];

export default function Links() {
  const location = useLocation();

  return (
    <div className="helpful-links">
      <div className="helpful-links__container">
        <ul className="helpful-links__container__list">
          {links.map((link) => {
            return (
              <li key={link.to}>
                <Link
                  to={`/links/${link.to}`}
                  className={
                    location.pathname.slice(7) === link.to
                      ? "helpful-links__container__list__anchor--active"
                      : "helpful-links__container__list__anchor"
                  }
                  onClick={() =>
                    window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
                  }
                >
                  {link.title}
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
      <Outlet />
    </div>
  );
}
