import { Route, Routes } from "react-router-dom";
import { useEffect, useState } from "react";

import ContactForm from "./pages/forms/ContactForm";
import HelpfulLink from "./pages/links/HelpfulLink";
import CookieSetter from "./components/CookieSetter";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Services from "./pages/Services";
import Projects from "./pages/Projects";
import Links from "./pages/links";
import Forms from "./pages/forms";
import About from "./pages/About";
import Error from "./pages/Error";
import Home from "./pages/Home";

export default function App() {
  // drives Google analytic functionality
  const [cookieOption, setCookieOption] = useState("essential");
  // checkCompleted is required to avoid rendering for split second when
  // hasCookie is true but refreshed and hasCookie becomes false again before
  // useEffect is run to determine if there is a cookie or not
  const [checkCompleted, setCheckCompleted] = useState(false);
  // helps display CookieSetter
  const [hasCookie, setHasCookie] = useState(false);

  // Function to set the cookie
  function setCookie(val) {
    // Set the cookie using document.cookie
    document.cookie = `allowCookies=${val}; expires=${new Date(
      Date.now() + 7 * 24 * 60 * 60 * 1000
    ).toUTCString()}`;

    // Update the states
    setHasCookie(true);
    setCookieOption(val);
  }

  // Function to read the cookie on component mount
  useEffect(() => {
    // Probably does not need split
    const existingCookie = document.cookie
      .split("; ")
      .find((row) => row.startsWith("allowCookies="))
      ?.split("=")[1];

    if (existingCookie) {
      setHasCookie(true);
      setCookieOption(existingCookie);
    }
    setCheckCompleted(true);
  }, []);

  return (
    <>
      <Navbar />
      {!hasCookie && checkCompleted && <CookieSetter setCookie={setCookie} />}
      <Routes>
        <Route path="/" element={<Home cookieOption={cookieOption} />} />
        <Route path="/services" element={<Services />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/aboutus" element={<About />} />
        <Route path="/links" element={<Links />}>
          <Route
            path="/links/warranty"
            element={<HelpfulLink title="Warranty" />}
          />
          <Route
            path="/links/terms"
            element={<HelpfulLink title="Terms & Conditions" />}
          />
          <Route
            path="/links/privacy"
            element={<HelpfulLink title="Privacy Policy" />}
          />
        </Route>
        <Route path="/forms" element={<Forms />}>
          <Route
            path="/forms/contact"
            element={<ContactForm key="Contact" title="Contact Form" />}
          />
          <Route
            path="/forms/quote"
            element={<ContactForm key="Quote" title="Quote Form" />}
          />
          <Route
            path="/forms/application"
            element={<ContactForm key="Application" title="Application Form" />}
          />
        </Route>
        <Route path="*" element={<Error />} />
      </Routes>
      <Footer />
    </>
  );
}
