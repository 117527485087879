import Container from "../generic/Container";
import Review from "./Review";

import "./index.css";

// These will eventually be driven by Google Reviews
const reviews = [
  {
    id: 0,
    name: "Blake Danchison",
    review:
      "Extremely happy with the work Futurecorp Electrical did on our home. They upgraded the main switch board and installed down lights in every room making our home modern.",
    rating: 5,
  },
  {
    id: 1,
    name: "Mary Muscolo",
    review:
      "Aleks and Travis done a marvellous job on the electrical supply and fittings on my home, they were prompt, consistent and completed the work effectively. Highly recommend!",
    rating: 5,
  },
  {
    id: 2,
    name: "Hus Huseyin",
    review:
      "Thank you to both Aleks and Travis. Exteremly professional, polite, honest, capable and tidy work. You guys have raised the bar for all tradies, thank you! 10/10",
    rating: 5,
  },
  {
    id: 3,
    name: "Bill Visitin",
    review:
      "Travis has done several jobs for me now and I can't rate him highly enough. Fantastic skills, offers solutions, fast efficient and reliable, top guy!",
    rating: 5,
  },
];

export default function Reviews() {
  const reviewList = reviews.map((review) => {
    return <Review key={review.id} review={review} />;
  });
  return (
    <Container type="reviews" title="Customer Reviews and Testaments">
      <div className="reviews">{reviewList}</div>
    </Container>
  );
}
