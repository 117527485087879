import { Fragment } from "react";
import "./About.css";

const aboutDetails = [
  {
    heading: "About Futurecorp Electrical",
    paragraphs: [
      "Welcome to Futurecorp Electrical - the electrical company that is lighting a path to a better world. Founded in 2022 by directors and co-founders Aleks Stijakovic and Travis Dombroski, both of whom have over a decade of experience in the industry, Futurecorp Electrical specializes in high-end residential, commercial, industrial, and electric vehicles.",
      "At Futurecorp Electrical, we strive to be quick, efficient, and responsive to our clients' needs. We understand the importance of delivering high-quality work while maintaining a clean and safe work environment. We also value our employees and provide them with the support they need to succeed, both personally and professionally. Our commitment to mental well-being is also a top priority. We understand the stresses of the industry and aim to help our clients and employees alike to achieve a healthy work-life balance.",
      "We believe in the power of logical thinking to create innovative solutions and the importance of emotional thinking to build strong relationships with our clients and employees. Our future goals include being a big part of the move towards economical sources of energy on the planet, reducing the carbon footprint, and setting examples for other electrical companies.",
      "We want individuals and businesses to think of Futurecorp Electrical when they need an expert in the electrical field. We want all Futurecorp members to feel like they're part of something bigger but at the same time part of a family that will always be there for each other. We also believe in giving back to the community and helping those in need. We strive to make a difference in the world, not just in the electrical industry. Join us on our journey towards a brighter future. Contact us today to learn more about our services and how we can assist you.",
    ],
  },
  {
    heading: "Founders",
    paragraphs: [
      "Aleks and Travis's journey began as apprentices in the electrical field where they met, they shared a passion for learning and mastering their craft. Upon obtaining their licenses, they embarked on separate paths as subcontractors, each striving to carve out their niche in the industry.",
      "Though trading under different names both were driven by a commitment to excellence and customer satisfaction. As their client bases expanded over two years, they found themselves collaborating more frequently, leveraging each other's expertise to deliver results.",
      'It was during one of these collaborative projects that Travis proposed the idea of founding a company together. Recognising the potential of joining forces, Aleks and Travis decided to merge their businesses, forming "Futurecorp Electrical" as equal shareholders in 2022.',
      "Since then, their focus has shifted from being technicians to entrepreneurs, with a shared vision of revolutionising the electrical industry. They are dedicated to fostering innovation, leaving a lasting impact on clients and the environment, prioritising the mental and physical well-being of their team, and setting ambitious goals for the company's growth.",
      'At the heart of Futurecorp Electrical lies the motto, "Lighting a Path to a Better World," serving as the guiding principle that shapes every decision and action. With this ethos as their compass, Aleks and Travis are committed to leading the company into a brighter future, one that is defined by progress, sustainability, and positive change.',
    ],
  },
];

// Would be nice to have a picture here.
export default function About() {
  return (
    <article className="about">
      {aboutDetails.map((d) => (
        <Fragment key={d.heading}>
          <h1 className="about__title">{d.heading}</h1>
          {d.paragraphs.map((p, i) => (
            <p key={i} className="about__paras">
              {p}
            </p>
          ))}
        </Fragment>
      ))}
    </article>
  );
}
