import ReCAPTCHA from "react-google-recaptcha";
import { useRef, useState } from "react";
import { Link } from "react-router-dom";

import Container from "../../components/generic/Container";
import Button from "../../components/generic/Button";

import applicationImg from "../../assets/application-img.jpg";
import contactImg from "../../assets/contact-img.jpg";
import quoteImg from "../../assets/quote-img.jpg";

import "./ContactForms.css";

const forms = [
  {
    title: "Contact Form",
    desc: "Let us know how we can help you by filling the email form. Our friendly staff will get back to you ASAP.",
    img: contactImg,
    placeholder: "How can we help?",
  },
  {
    title: "Quote Form",
    desc: "No job is too difficult us, please provide a detailed description for the best possible help.",
    img: quoteImg,
    placeholder: "Description of work",
  },
  {
    title: "Application Form",
    desc: "Here at Futurecorp Electrical we are always looking to grow our team. Please upload your resume and cover letter.",
    img: applicationImg,
    placeholder: "Body",
  },
];

const initialForm = {
  name: "",
  email: "",
  phone: "",
  subject: "",
  message: "",
  coverLetter: null,
  resume: null,
  acceptedTC: false,
};

export default function ContactForm({ title }) {
  const [formData, setFormData] = useState(initialForm);
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(null);
  const captchaRef = useRef(null);
  const resumeRef = useRef(null);
  const coverLetterRef = useRef(null);

  const form = forms.find((f) => f.title === title);

  // Event Handler Functions
  async function handleSubmit(e) {
    e.preventDefault();

    // FormData is used because of needing files to be sent to BE
    // (Content-Type: multipart/form-data && Automatic boundary setting)
    const fmData = new FormData();
    for (let [key, value] of Object.entries(formData)) {
      fmData.append(key, value);
    }
    fmData.append("formType", form.title);
    fmData.append("recaptchaToken", isCaptchaVerified);

    if (formData.acceptedTC) {
      if (isCaptchaVerified) {
        if (form.title === "Application Form") {
          if (formData.resume) {
            postEmail(fmData, true);
          } else {
            alert("Please attach your resume.");
          }
        } else {
          postEmail(fmData);
        }
      } else {
        alert('Please click the "I\'m not a robot" button.');
      }
    } else {
      alert("Please accept the T&Cs.");
    }
  }

  function handleChange(e) {
    if (e.target.type === "file") {
      // Even though type might equal file
      // user might have cancelled which means e.target.files.length === 0
      if (e.target.files.length) {
        // Check if file is a PDF
        if (e.target.files[0].name.slice(-3) === "pdf") {
          const KILOBYTE = 1024;
          const sizeRestriction = 2;
          // Check if file is smaller than 2MB
          if (e.target.files[0].size < KILOBYTE * KILOBYTE * sizeRestriction) {
            setFormData((prevFormData) => ({
              ...prevFormData,
              [e.target.id]: e.target.files[0],
            }));
          } else {
            removeAndAlert(e.target.id, "File must be under 2MB. Please try again.");
          }
        } else {
          removeAndAlert(e.target.id, "PDF files are only allowed. Please try again.");
        }
      } else {
        setFormData((prevFormData) => ({
          ...prevFormData,
          [e.target.id]: null,
        }));
      }
    } else if (e.target.type === "checkbox") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        acceptedTC: e.target.checked,
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [e.target.name]: e.target.value,
      }));
    }
  }

  // Helper Functions
  // helps handleSubmit()
  async function postEmail(fmData, hasFiles) {
    try {
      // FormData was needed due to there being files and needing
      // the "Content-Type": "multipart/form-data"
      // boundary is not included when the content-type is added manually
      const res = await fetch(process.env.REACT_APP_API_URL, {
        method: "POST",
        body: fmData,
      });
      // If successful
      if (res.ok) {
        setFormData(initialForm);
        setIsCaptchaVerified(null);
        captchaRef.current.reset();
        if (hasFiles) {
          resumeRef.current.value = "";
          coverLetterRef.current.value = "";
        }
        alert("Email sent successfully!");
      } else {
        throw new Error("Request failed with status " + res.status);
      }
    } catch (err) {
      alert("Issue with sending your email. Please try again.");
    }
  }

  // helps handleChange(), specifically with e.target.type === "file"
  function removeAndAlert(id, string) {
    if (id === "resume") resumeRef.current.value = "";
    else coverLetterRef.current.value = "";
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: null,
    }));
    alert(string);
  }

  return (
    <div className="forms__form">
      <div className="forms__form__img-cont">
        <img
          src={form.img}
          alt="form-img"
          className="forms__form__img-cont__img"
        />
        <p className="forms__form__img-cont__caption">
          Designed by{" "}
          <a
            href="www.freepik.com"
            className="forms__form__img-cont__caption__link"
          >
            Freepik
          </a>
        </p>
      </div>
      <Container type="contactForm" title={form.title}>
        <div className="forms__form__contact">
          <div className="forms__form__contact__desc">{form.desc}</div>
          <form
            className="forms__form__contact-form"
            onSubmit={handleSubmit}
            encType="multipart/form-data"
            autoComplete="off"
          >
            <div className="forms__form__contact-form__fields">
              <input
                type="text"
                name="name"
                className="forms__form__contact-form__fields__input"
                placeholder="Name"
                autoComplete="off"
                onChange={handleChange}
                value={formData.name}
              />
              <input
                type="text"
                name="phone"
                className="forms__form__contact-form__fields__input"
                placeholder="Phone"
                autoComplete="off"
                value={formData.phone}
                onChange={handleChange}
              />
              <input
                required
                type="email"
                name="email"
                className="forms__form__contact-form__fields__input"
                placeholder="Email"
                autoComplete="off"
                value={formData.email}
                onChange={handleChange}
              />
              <input
                type="text"
                name="subject"
                className="forms__form__contact-form__fields__input"
                placeholder="Subject"
                value={formData.subject}
                onChange={handleChange}
              />
              <textarea
                type="text"
                name="message"
                className="forms__form__contact-form__fields__textarea"
                placeholder={form.placeholder}
                value={formData.message}
                onChange={handleChange}
              />
              {form.title === "Application Form" && (
                <>
                  <div style={{ display: "flex" }}>
                    <div>
                      <label
                        htmlFor="resume"
                        className="forms__form__contact-form__fields__label"
                      >
                        <span className="forms__form__contact-form__fields__label__required">
                          {"* "}
                        </span>
                        Resume:
                      </label>
                      <input
                        type="file"
                        accept=".pdf"
                        id="resume"
                        ref={resumeRef}
                        onChange={handleChange}
                        className="forms__form__contact-form__fields__upload"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="coverLetter"
                        className="forms__form__contact-form__fields__label"
                      >
                        Cover Letter:
                      </label>
                      <input
                        type="file"
                        accept=".pdf"
                        id="coverLetter"
                        ref={coverLetterRef}
                        onChange={handleChange}
                        className="forms__form__contact-form__fields__upload"
                      />
                    </div>
                  </div>
                  <p className="forms__form__contact-form__fields__restrictions">
                    PDF files allowed only, under 2MB.
                  </p>
                </>
              )}
              {/* Creates issues in Issue tab*/}
              <ReCAPTCHA
                ref={captchaRef}
                sitekey={process.env.REACT_APP_RECAPTCHA_CLIENT_KEY}
                onChange={(value) => setIsCaptchaVerified(value)}
                theme="dark"
              />
              <label className="forms__form__contact-form__fields__checkbox-label">
                <input
                  type="checkbox"
                  name="acceptedTC"
                  checked={formData.acceptedTC}
                  onChange={handleChange}
                  className="forms__form__contact-form__fields__checkbox-box"
                />
                I have read the{" "}
                <Link
                  to="/links/terms"
                  className="forms__form__contact-form__fields__checkbox-link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  T&Cs
                </Link>{" "}
                and agree with them.
              </label>
            </div>
            <Button className="forms__form__contact-form__button" type="submit">
              Submit
            </Button>
          </form>
        </div>
      </Container>
    </div>
  );
}
