import Container from "./generic/Container";

import "./Services.css";

const elecServices = [
  "Power",
  "Lighting",
  "Emergency Lighting",
  "Switch Board Upgrades",
  "Electric Vehicles",
  "LED Upgrades",
];

const dataServices = [
  "Ethernet Cabling",
  "Patch Panels",
  "CCTV & MATV",
  "Security Alarms",
  "Intercoms",
  "Speakers",
];

export default function Services() {
  const elecServicesList = elecServices.map((service) => {
    return <li key={service}>{service}</li>;
  });
  const dataServicesList = dataServices.map((service) => {
    return <li key={service}>{service}</li>;
  });

  return (
    <Container type="services" title="Services">
      <div className="services">
        <div className="services__elec">
          <h4>Electrical</h4>
          <ul>{elecServicesList}</ul>
        </div>
        <div className="services__data">
          <h3>Data & Communication</h3>
          <ul>{dataServicesList}</ul>
        </div>
      </div>
    </Container>
  );
}
