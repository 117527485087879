import emptyStar from "../../assets/empty-star.png";
import halfStar from "../../assets/half-star.png";
import star from "../../assets/star.png";

import "./Review.css";

export default function Review({ review }) {
  return (
    <div className="review">
      {/* <div className="servicem8-feedback">
        Feedback by <a href="//servicem8.com/">ServiceM8</a>
      </div> */}
      <div className="review__title">{review.name}</div>
      <div className="review__review">{review.review}</div>
      <div className="review__rating">
        <img
          className="review__rating__img"
          src={
            review.rating > 0.5
              ? star
              : review.rating > 0.49
              ? halfStar
              : emptyStar
          }
          alt={
            review.rating > 0.5
              ? "Star"
              : review.rating > 0.49
              ? "Half Star"
              : "Empty Star"
          }
        />
        <img
          className="review__rating__img"
          src={
            review.rating > 1.5
              ? star
              : review.rating > 1.49
              ? halfStar
              : emptyStar
          }
          alt={
            review.rating > 1.5
              ? "Star"
              : review.rating > 1.49
              ? "Half Star"
              : "Empty Star"
          }
        />
        <img
          className="review__rating__img"
          src={
            review.rating > 2.5
              ? star
              : review.rating > 2.49
              ? halfStar
              : emptyStar
          }
          alt={
            review.rating > 2.5
              ? "Star"
              : review.rating > 2.49
              ? "Half Star"
              : "Empty Star"
          }
        />
        <img
          className="review__rating__img"
          src={
            review.rating > 3.5
              ? star
              : review.rating > 3.49
              ? halfStar
              : emptyStar
          }
          alt={
            review.rating > 3.5
              ? "Star"
              : review.rating > 3.49
              ? "Half Star"
              : "Empty Star"
          }
        />
        <img
          className="review__rating__img"
          src={
            review.rating > 4.5
              ? star
              : review.rating > 4.49
              ? halfStar
              : emptyStar
          }
          alt={
            review.rating > 4.5
              ? "Star"
              : review.rating > 4.49
              ? "Half Star"
              : "Empty Star"
          }
        />
      </div>
    </div>
  );
}
