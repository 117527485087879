// import ReactGA from "react-ga";

import Projects from "../components/Projects";
import Services from "../components/Services";
import Reviews from "../components/Reviews";
import Clients from "../components/Clients";
import Hero from "../components/Hero";

import "./Home.css";

export default function Home({ cookieOption }) {
  // if (cookieOption === "all") {
  //   ReactGA.initialize("G-VXZ5RPJGEQ");
  //   ReactGA.pageview(window.location.pathname + window.location.search);
  // }

  return (
    <div className="home">
      <Hero />
      <Services />
      <Projects />
      <Reviews />
      <Clients />
    </div>
  );
}
