import { Link } from "react-router-dom";
import Button from "./generic/Button";

import "./CookieSetter.css";

export default function CookieSetter({ setCookie }) {
  return (
    <div className="cookies">
      <div className="cookies__desc">
        This website uses cookies to better enhance the experience of your
        visit. There are essential cookies that are required for the website to
        run correctly and securely and there are extra cookies for the
        enhancement of your experience. You can also view our privacy policy{" "}
        <Link to="/links/privacy" className="cookies__desc__link">
          here
        </Link>{" "}
        to better understand how we handle your data.
      </div>
      <div className="cookies__buttons">
        <Button onClick={() => setCookie("all")}>Allow All Cookies</Button>
        <Button onClick={() => setCookie("essential")}>
          Essential Cookies Only
        </Button>
      </div>
    </div>
  );
}
