// Using @vis.gl/react-google-maps
// Currenlty an alpha release so might change and need maintenance
import { APIProvider, AdvancedMarker, Map } from "@vis.gl/react-google-maps";

export default function NewMap() {
  const position = { lat: -33.91779423959833, lng: 151.1876613268167 };

  return (
    <APIProvider apiKey={process.env.REACT_APP_GOOGLE_API_KEY}>
      <div style={{ width: "40%" }}>
        <Map
          defaultCenter={position}
          defaultZoom={14}
          mapId={process.env.REACT_APP_GOOGLE_MAP_ID}
          style={{ borderRadius: "10px" }}
        >
          <AdvancedMarker position={position} />
        </Map>
      </div>
    </APIProvider>
  );
}
