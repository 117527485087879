import { Link } from "react-router-dom";

export default function LinkList({ title, to, scrollTo }) {
  return (
    <li className="footer__primary__links__list__item">
      <Link
        className="footer__primary__links__list__item__anchor"
        to={to}
        onClick={scrollTo}
      >
        {title}
      </Link>
    </li>
  );
}
