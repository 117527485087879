import { CSSTransition } from "react-transition-group";
import { motion } from "framer-motion";
import { useRef } from "react";

import LinkButton from "./generic/LinkButton";
import downArr from "../assets/down.png";

import "./Hero.css";

export default function Hero() {
  // for CSSTransition
  const ref = useRef(null);
  return (
    <div className="hero-container">
      <CSSTransition
        in={true}
        appear={true}
        timeout={0}
        classNames={"hero-details"}
        nodeRef={ref}
      >
        <div ref={ref} className="hero-details">
          <span className="hero-details__title">
            Lighting a path to a better world.
          </span>
          <div className="hero-details__buttons">
            <LinkButton to="/forms/contact">Got a Question?</LinkButton>
            <LinkButton to="/forms/quote" isDark>
              Want a Quote?
            </LinkButton>
          </div>
        </div>
      </CSSTransition>
      <motion.img
        initial={{ opacity: 1, width: 100 }}
        animate={{ opacity: 0.4, width: 90 }}
        transition={{
          repeat: Infinity,
          repeatType: "reverse",
          duration: 2,
        }}
        className="downArr"
        src={downArr}
        alt="down arrow"
      />
    </div>
  );
}
