import bradford from "../assets/bradford.svg";
import clipsal from "../assets/clipsal.svg";
import dynamic from "../assets/dynamic.png";
import neca from "../assets/neca.png";
import redox from "../assets/redox.svg";

import "./Clients.css";
import Container from "./generic/Container";

export default function Clients() {
  return (
    <Container type="clients" title="Clients & Partners">
      <div className="clients">
        <img className="clients__bradford" src={bradford} alt="bradford" />
        <img className="clients__clipsal" src={clipsal} alt="clipsal" />
        <img className="clients__dynamic" src={dynamic} alt="dynamic" />
        <img className="clients__neca" src={neca} alt="neca" />
        <img className="clients__redox" src={redox} alt="redox" />
      </div>
    </Container>
  );
}
