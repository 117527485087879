import "./index.css";

// Used in Forms
export default function Button({
  children,
  className = null,
  type = "button",
  onClick = null,
}) {
  return (
    <button
      className={`genericButton${className ? ` ${className}` : ""}`}
      type={type}
      onClick={onClick}
    >
      {children}
    </button>
  );
}
