import { Link, Outlet, useLocation } from "react-router-dom";

import "./index.css";

const form = [
  {
    to: "contact",
    title: "Questions",
  },
  {
    to: "quote",
    title: "Quote",
  },
  {
    to: "application",
    title: "Application",
  },
];

export default function Forms() {
  const location = useLocation();
  const linksJSX = form.map((form) => {
    return (
      <Link
        key={form.to}
        to={`/forms/${form.to}`}
        className={`${
          location.pathname.slice(7) === form.to
            ? "forms__links__link--active"
            : "forms__links__link"
        }`}
      >
        {form.title}
      </Link>
    );
  });

  return (
    <div className="forms">
      <div className="forms__links">{linksJSX}</div>
      <Outlet />
    </div>
  );
}
