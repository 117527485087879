import { Link, useLocation } from "react-router-dom";

import logo from "../assets/logo.png";

import "./Navbar.css";

const navList = [
  {
    name: "Home",
    link: "/",
  },
  {
    name: "Services",
    link: "/services",
  },
  {
    name: "About Us",
    link: "/aboutus",
  },
  {
    name: "Contact",
    link: "/forms/contact",
  },
];

export default function Navbar() {
  const location = useLocation();
  const linkActive = location.pathname.slice(1);

  function scrollTo() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }

  const printNavList = navList.map((nav) => {
    return (
      <li
        className={`navbar__links__item${
          nav.link.slice(1) === linkActive ? " navbar__links__item--active" : ""
        }`}
        key={nav.name}
      >
        <Link
          to={nav.link}
          className={`navbar__links__item__anchor${
            nav.link.slice(1) === linkActive
              ? " navbar__links__item__anchor--active"
              : ""
          }`}
          onClick={scrollTo}
        >
          <div className="navbar__links__item__anchor__text">{nav.name}</div>
        </Link>
      </li>
    );
  });

  // For mobile navbar
  // const printDropdownList = navList.map((nav, index) => {
  //   return (
  //     <li
  //       onClick={() => setLinkActive(nav[0])}
  //       className={`navbar__dropdown__links__item${
  //         nav[0] === linkActive ? " navbar__dropdown__links__item--active" : ""
  //       }`}
  //       key={index}
  //     >
  //       <Link
  //         to={nav[1]}
  //         className={`navbar__dropdown__links__item__anchor${
  //           nav[0] === linkActive
  //             ? " navbar__dropdown__links__item__anchor--active"
  //             : ""
  //         }`}
  //       >
  //         {nav[0]}
  //       </Link>
  //     </li>
  //   );
  // });

  return (
    <nav className="navbar">
      <div className="contact-info">
        To contact us directly, please call{" "}
        <a className="contact-info__number" href="tel:0423 500 520">
          1300 403 578
        </a>
        . You could also send us an email directly at{" "}
        <a
          className="contact-info__email"
          href="mailto:admin@futurecorpelectrical.com.au"
        >
          admin@futurecorpelectrical.com.au
        </a>
        .
      </div>
      <div className="main-container">
        <Link to="/">
          <img src={logo} alt="" className="navbar__img" />
        </Link>
        <ul className="navbar__links">{printNavList}</ul>
        {/* Phone Menu */}
        {/* <svg
          onClick={() => setIsMenuActive((prev) => !prev)}
          className="navbar__phone-menu"
        >
          <line x1="0" y1="8%" x2="100%" y2="8%" />
          <line x1="0" y1="50%" x2="100%" y2="50%" />
          <line x1="0" y1="92%" x2="100%" y2="92%" />
        </svg> */}
      </div>

      {/* <div className="sec-container">
        <ul
          className={
            isMenuActive === true
              ? "navbar__dropdown"
              : "navbar__dropdown--hidden"
          }
        >
          {printDropdownList}
        </ul>
      </div> */}
    </nav>
  );
}
