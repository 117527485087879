import { Fragment } from "react";
import "./Services.css";

const services = [
  {
    title: "Electrical Services",
    desc: "With Futurecorp Electrical, we offer to look after the needs of our clients from the first phone call to the completion of the job. The division is geared to providing a service covering new installations, additions, and alterations for residential and commercial.",
    list: [
      "Design and construct",
      "Switchboard and metering",
      "Light and power",
      "Exit and emergency lighting",
      "LED upgrade",
      "Lighting control",
      "Home automation",
    ],
  },
  {
    title: "Communication and Security",
    desc: "As the world is getting more connected, Futurecorp Electrical is aware of the importance that lies with providing excellent service to meet our clients' needs.",
    list: [
      "Design and construct",
      "Data",
      "Wireless access point",
      "Power over ethernet",
      "CCTV",
      "MATV",
      "Intercom",
      "Security system",
    ],
  },
  {
    title: "Industrial",
    desc: "As a critical part of any business, we understand that having operations running as efficiently as possible will facilitate the best results for you and your customers.",
    list: [
      "Design and construct",
      "Switchboard maintenance and upgrade",
      "Motor testing and maintenance",
      "PLC system",
      "UPS",
      "LED high bay upgrade",
      "Power supply to machines and equipment",
      "Test and tagging",
    ],
  },
  {
    title: "Maintenance",
    desc: "Our clients can rely on Futurecorp Electrical to be a phone call away. Unexpectable situations can cause companies and homeowners to have to pause or cancel their plans, we’re confident in our experience to offer a solution.",
    list: [
      "Over the phone help",
      "Emergency call out",
      "Preventative measures",
      "Source and replace",
      "RCD safety switches",
      "Cable testing",
      "Earthing system",
      "Test and tag",
    ],
  },
  {
    title: "Sustainable Energy",
    desc: "As our company name and motto suggest, we are looking towards the future of a healthy sustainable planet. With all of our needs demanding more energy every day it's our mission to play a role in making that achievable in a sustainable way.",
    list: ["Solar power", "EV chargers", "UPS systems", "LED lighting"],
  },
];

// An image here would be nice
export default function Services() {
  return (
    <div className="service-details">
      <article>
        {services.map((service) => (
          <Fragment key={service.title}>
            <h1 className="service-details__title">{service.title}</h1>
            <p className="service-details__para">{service.desc}</p>
            <ul className="service-details__list">
              {service.list.map((item) => (
                <li key={item}>{item}</li>
              ))}
            </ul>
          </Fragment>
        ))}
      </article>
    </div>
  );
}
