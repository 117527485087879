// import { CSSTransition } from "react-transition-group";
// import { useRef, useState } from "react";
// import { createPortal } from "react-dom";
import { useState } from "react";

import Container from "./generic/Container";

import burraneer from "../assets/burraneer.jpg";
import collaroy from "../assets/collaroy.jpg";
import rosebay from "../assets/rosebay.jpg";
import redox from "../assets/redox.jpg";

import "./Projects.css";

const projects = [
  {
    id: 0,
    name: "Collaroy",
    category: "Residential",
    img: collaroy,
  },
  {
    id: 1,
    name: "Burraneer",
    category: "Residential",
    img: burraneer,
  },
  {
    id: 2,
    name: "Rose Bay",
    category: "Residential",
    img: rosebay,
  },
  {
    id: 3,
    name: "Redox",
    category: "Commercial",
    img: redox,
  },
  {
    id: 4,
    name: "Collaroy",
    category: "Commercial",
    img: collaroy,
  },
  {
    id: 5,
    name: "Burraneer",
    category: "Industrial",
    img: burraneer,
  },
  {
    id: 6,
    name: "Rose Bay",
    category: "Residential",
    img: rosebay,
  },
  {
    id: 7,
    name: "Redox",
    category: "Industrial",
    img: redox,
  },
  {
    id: 8,
    name: "Collaroy",
    category: "Commercial",
    img: collaroy,
  },
];

export default function Projects() {
  // const [showModal, setShowModal] = useState(null);
  const [activeSection, setActiveSection] = useState("Residential");
  const resProjectList = projects
    .filter((p) => p.category === "Residential")
    .map((project) => {
      return (
        <Project
          key={project.id}
          project={project}
          // showModal={showModal}
          // setShowModal={setShowModal}
        />
      );
    });

  const comProjectList = projects
    .filter((p) => p.category === "Commercial")
    .map((project) => {
      return (
        <Project
          key={project.id}
          project={project}
          // showModal={showModal}
          // setShowModal={setShowModal}
        />
      );
    });

  const indProjectList = projects
    .filter((p) => p.category === "Industrial")
    .map((project) => {
      return (
        <Project
          key={project.id}
          project={project}
          // showModal={showModal}
          // setShowModal={setShowModal}
        />
      );
    });

  // Feel like container might not be needed here
  return (
    <Container type="projects" title="Projects">
      {/* h3 and ul could be a small component */}
      <ul className="projects-container__selection">
        <li
          className={`projects-container__selection__item ${
            activeSection === "Residential" &&
            "projects-container__selection__item--active"
          }`}
          onClick={() => setActiveSection("Residential")}
        >
          Residential
        </li>
        <li
          className={`projects-container__selection__item ${
            activeSection === "Commercial" &&
            "projects-container__selection__item--active"
          }`}
          onClick={() => setActiveSection("Commercial")}
        >
          Commercial
        </li>
        <li
          className={`projects-container__selection__item ${
            activeSection === "Industrial" &&
            "projects-container__selection__item--active"
          }`}
          onClick={() => setActiveSection("Industrial")}
        >
          Industrial
        </li>
      </ul>
      {activeSection === "Residential" && (
        <ul className="projects">{resProjectList}</ul>
      )}
      {activeSection === "Commercial" && (
        <ul className="projects">{comProjectList}</ul>
      )}
      {activeSection === "Industrial" && (
        <ul className="projects">{indProjectList}</ul>
      )}
    </Container>
  );
}

function Project({ project }) {
  // function Project({ project, showModal, setShowModal }) {
  // function handleClick() {
  //   setShowModal(project.id);
  //   // Checks if currently running a browser environment
  //   // and also double check that the DOM is accesible
  //   if (typeof window != "undefined" && window.document) {
  //     document.body.style.overflow = "hidden";
  //   }
  // }

  return (
    <>
      <li
        // onClick={handleClick}
        className="projects__item"
        style={{ backgroundImage: `url(${project.img})` }}
      >
        <h4 className="projects__item__title">{project.name}</h4>
      </li>
      {/* createPortal is used to add the Modal node somewhere else in the document */}
      {/* {createPortal(
        <Modal
          project={project}
          showModal={showModal}
          setShowModal={setShowModal}
        />,
        document.body
      )} */}
    </>
  );
}
