import { Fragment } from "react";

import "./HelpfulLink.css";

const date = {
  format: "2024-03-30",
  string: "Mar 30, 2024",
};
const links = [
  {
    title: "Warranty",
    paragraphs: [
      "At Futurecorp Electrical, we stand behind the quality of our work and are committed to ensuring customer satisfaction. All electrical installations performed by our team come with a 12-month warranty from the date of completion.",
      "This warranty covers any defects or malfunctions in the electrical installation that occur due to faulty workmanship or materials used. If a problem arises within the warranty period, simply contact us and we will promptly address the issue at no additional cost to you.",
      "Please note that this warranty does not cover damage or malfunctions caused by misuse, neglect, or natural disasters. Additionally, any modifications made to the electrical installation by someone other than our team may void the warranty.",
      "At Futurecorp Electrical, we are dedicated to providing high-quality electrical services that meet the needs and expectations of our clients. Contact us today to learn more about our services and our 12-month warranty on electrical installations.",
    ],
  },
  {
    title: "Terms & Conditions",
    desc: "Welcome to Futurecorp Electrical. These terms and conditions govern your use of our services, including all electrical installations, repairs, and maintenance. By using our services, you agree to be bound by these terms and conditions.",
    paragraphs: [
      {
        heading: "1. Services",
        content:
          "We provide electrical services to residential, commercial and industrial customers. Our services include electric vehicle charging installation, construction builds, switchboard upgrades, and all other electrical work.",
      },
      {
        heading: "2. Payment",
        content:
          "We require payment for our services upon completion of the work, unless otherwise agreed upon in writing. Payment can be made by cash, check, or credit card.",
      },
      {
        heading: "3. Warranty",
        content:
          "All electrical installations performed by our team come with a 12-month warranty from the date of completion. This warranty covers any defects or malfunctions in the electrical installation that occur due to faulty workmanship or materials used. See our warranty statement for more details.",
      },
      {
        heading: "4. Liability",
        content:
          "We are not liable for any damages, injuries, or losses that may occur as a result of the use of our services, unless caused by our gross negligence or wilful misconduct. In no event shall our liability exceed the total amount paid by you for our services.",
      },
      {
        heading: "5. Safety",
        content:
          "Electrical work can be dangerous and potentially fatal if not performed by qualified professionals. By using our services, you acknowledge that you understand and accept the risks associated with electrical work and agree to comply with all safety requirements.",
      },
      {
        heading: "6. Intellectual Property",
        content:
          "All content, including text, graphics, logos, and images, on our website and promotional materials are owned by Futurecorp Electrical and protected by copyright law. You may not use or reproduce our content without our written permission.",
      },
      {
        heading: "7. Governing Law",
        content:
          "These terms and conditions are governed by the laws of NSW in which our services are provided.",
      },
      {
        heading: "8. Modification",
        content:
          "We reserve the right to modify these terms and conditions at any time without prior notice. Your continued use of our services after any changes are made constitutes acceptance of the modified terms. Thank you for choosing Futurecorp Electrical. If you have any questions or concerns about these terms and conditions, please contact us.",
      },
    ],
  },
  {
    title: "Privacy Policy",
    paragraphs: [
      "At Futurecorp Electrical, we are committed to protecting your privacy and ensuring the security of your personal information. We will never sell or disclose your personal information to any third party, except as required by law or with your explicit consent.",
      "We collect personal information from our customers only when it is necessary to provide our electrical services, such as when scheduling appointments, providing quotes, or processing payments. This information may include your name, address, phone number, email address, and payment information. We use this information solely for the purpose of providing our services to you and ensuring the quality of our work.",
      "We take reasonable steps to ensure that your personal information is kept secure and protected from unauthorized access, use, or disclosure. We use industry-standard security measures, such as firewalls and encryption, to protect your information from potential breaches.",
      "In addition, we may use your personal information to contact you with information about our services or to request feedback on your experience with our company. You may opt out of receiving marketing communications from us at any time.",
      "If you have any questions or concerns about our privacy policy or how we use your personal information, please contact us. We are committed to maintaining the highest standards of privacy and security and will do our best to address any issues or concerns you may have.",
    ],
  },
];

export default function HelpfulLink({ title }) {
  const link = links.find((l) => l.title === title);
  return (
    <article className="helpful-links__body">
      <h2 className="helpful-links__body__title">{link.title}</h2>
      <p className="helpful-links__body__update">
        Last Updated:{" "}
        <time
          className="helpful-links__body__update__time"
          dateTime={date.format}
        >
          {date.string}
        </time>
      </p>
      {title === "Terms & Conditions" && (
        <p className="helpful-links__body__paras">{link.desc}</p>
      )}
      {title === "Terms & Conditions"
        ? link.paragraphs.map((p) => (
            <Fragment key={p.heading}>
              <h3 className="helpful-links__body__sub-title">{p.heading}</h3>
              <p className="helpful-links__body__paras">{p.content}</p>
            </Fragment>
          ))
        : link.paragraphs.map((p) => (
            <p key={p} className="helpful-links__body__paras">
              {p}
            </p>
          ))}
    </article>
  );
}
