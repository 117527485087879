import LinkButton from "../generic/LinkButton";
import Container from "../generic/Container";
import LinkList from "./LinkList";
import NewMap from "./NewMap";
import Social from "./Social";

import igAct from "../../assets/instagram-active.png";
import ig from "../../assets/instagram.png";
import logo from "../../assets/logo.png";
// import fbAct from "../assets/facebook-active.png";
// import ytAct from "../assets/youtube-active.png";
// import fb from "../assets/facebook.png";
// import yt from "../assets/youtube.png";

import "./index.css";

const links = [
  {
    to: "/links/warranty",
    title: "Warranty Statement",
  },
  {
    to: "/links/terms",
    title: "Terms & Conditions",
  },
  {
    to: "/links/privacy",
    title: "Privacy Policy",
  },
];

const linkButtons = [
  {
    to: "/forms/application",
    title: "Employment?",
  },
  {
    to: "/forms/quote",
    title: "A Quote?",
  },
];

const socials = [
  {
    href: "https://www.instagram.com/futurecorp_electrical_/",
    alt: "Instagram Link",
    rest: ig,
    act: igAct,
  },
];

export default function Footer() {
  const linksJSX = links.map((link) => {
    return (
      <LinkList
        key={link.title}
        to={link.to}
        title={link.title}
        scrollTo={scrollTo}
      />
    );
  });

  const linkButtonsJSX = linkButtons.map((lb) => {
    return (
      <LinkButton key={lb.title} to={lb.to} onClick={scrollTo} isSmall>
        {lb.title}
      </LinkButton>
    );
  });

  const socialsJSX = socials.map((social) => (
    <Social
      key={social.alt}
      href={social.href}
      alt={social.alt}
      rest={social.rest}
      act={social.act}
    />
  ));

  //For Helpful Links - scrolls back to the top of the page
  function scrollTo() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }

  return (
    <div className="footer__container">
      <div className="footer">
        <div>
          <img className="footer__logo" src={logo} alt="footer-logo" />
          <i className="footer__quote">Lighting a path to a better world.</i>
        </div>
        <div className="footer__primary">
          <Container title="Head Office" type="subtitle">
            <div className="footer__primary__office-body">
              7 / 77 Boure Rd, Alexandria, NSW 2015
            </div>
          </Container>
          <Container title="License Number" type="subtitle">
            <div className="footer__primary__license-body">#348123C</div>
          </Container>
          <Container title="Helpful Links" type="subtitle">
            <ul className="footer__primary__links__list">{linksJSX}</ul>
          </Container>
        </div>
        <div className="footer__secondary">
          <Container title="Are you looking for..." type="subtitle">
            <div className="footer__secondary__buttons">{linkButtonsJSX}</div>
          </Container>
          <Container title="Social Media" type="subtitle">
            <div className="footer__secondary__socials">{socialsJSX}</div>
          </Container>
        </div>
        <NewMap />
      </div>
      <hr />
      <div className="signature">© 2024 - Made with TLC by Yandhi</div>
    </div>
  );
}
